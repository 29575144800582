import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@src/layouts';
import { GlossaryContent } from '@pages-impl/glossary/content/GlossaryContent';

export default function glossaryTemplate(props) {
  const {
    glossary: {
      frontmatter: { title, seoTitle, seoDescription },
      html,
    },
    readMorePosts,
  } = props.data;

  return (
    <Layout {...props} oldPage>
      <GlossaryContent
        html={html}
        glossaryTitle={title}
        seoDescription={seoDescription}
        seoTitle={seoTitle}
        readMorePosts={readMorePosts}
      />
    </Layout>
  );
}

export const singleGlossaryQuery = graphql`
  query GlossaryReadMore($path: String, $relatedGlossaryWords: [String]) {
    glossary: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
        seoTitle
        seoDescription
      }
      html
    }
    readMorePosts: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "blog" }, blogId: { in: $relatedGlossaryWords } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            authors {
              frontmatter {
                surname
                name
                path
                avatar {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  extension
                  publicURL
                }
              }
            }
            blogId
            title
            path
            date
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
            shortDescription
            blogKeywords
            searchKeywords
            postCategories {
              frontmatter {
                postCategoryName
              }
            }
          }
          wordCount {
            words
          }
        }
      }
    }
  }
`;
